import { useEffect, useState } from "react";
import {
  getJobTitle,
  getDepartmentData,
  getSubDepartment,
  getTeam,
  userRole,
  getEmployetypeData,
} from "../../../redux/features/auth/authSlice";
import { useDispatch } from "react-redux";

function EmploymentDetails({ onChange, showUserComponent, onSubmit }) {
  const [jobData, setJobData] = useState([]);
  const [depData, setDepData] = useState([]);
  const [subDepData, setSubDepData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [userRoleData, setUserRoleData] = useState([]);
  const [employeeData, setEmployeeRoleData] = useState([]);

  const dispatch = useDispatch();

  const getJobTitleData = async () => {
    try {
      const res = await dispatch(getJobTitle());
      setJobData(res.payload);
    } catch (err) {
      console.error(err);
    }
  };

  const getEmployeeData = async () => {
    try {
      const res = await dispatch(getEmployetypeData());
      setEmployeeRoleData(res.payload);
    } catch (err) {
      console.error(err);
    }
  };

  const getDepartmentsData = async () => {
    try {
      const res = await dispatch(getDepartmentData());
      setDepData(res.payload);
    } catch (err) {
      console.error(err);
    }
  };

  const getSubDepartmentsData = async () => {
    try {
      const res = await dispatch(getSubDepartment());
      setSubDepData(res.payload);
    } catch (err) {
      console.error(err);
    }
  };

  const getTeamsData = async () => {
    try {
      const res = await dispatch(getTeam());
      setTeamData(res.payload);
    } catch (err) {
      console.error(err);
    }
  };

  const getUsersRoleData = async () => {
    try {
      const res = await dispatch(userRole());
      setUserRoleData(res.payload);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getEmployeeData();
    getUsersRoleData();
    getTeamsData();
    getSubDepartmentsData();
    getDepartmentsData();
    getJobTitleData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    onChange({ [name]: value });
  };
  return (
    <>
      <div className="step-tab-panel p-4" data-step="step2">
        <div className="row">
          <div className="col-12 col-sm-6">
            <label
              htmlFor="EmployeeTypeId"
              className="col-sm-12 col-form-label"
            >
              Employee Type
            </label>
            <div className="col-sm-12">
              <select
                name="EmployeeTypeId"
                id="EmployeeTypeId"
                className="form-control"
                style={{ width: "100%" }}
                onChange={handleInputChange}
              >
                <option defaultValue>Select EmployeeType</option>
                {employeeData &&
                  employeeData.length > 0 &&
                  employeeData.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <label htmlFor="Department" className="form-label">
              Department
            </label>
            <select
              name="Department"
              id="DepartmentId"
              className="form-select array-select form-control"
              aria-label="example"
              onChange={handleInputChange}
            >
              <option defaultValue>Select Department</option>
              {
                depData.length > 0 &&
                depData.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6">
            <label htmlFor="SubDepartment" className="form-label">
              Sub Department
            </label>
            <select
              name="SubDepartment"
              id="SubDepartmentId"
              className="form-select array-select form-control"
              onChange={handleInputChange}
            >
              <option defaultValue>Select SubDepartment</option>
              {subDepData &&
                subDepData.length > 0 &&
                subDepData.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-12 col-sm-6">
            <label htmlFor="Designation" className="form-label">
              Designation
            </label>

            <select
              name="Designation"
              id="DesignationId"
              className="form-select array-select form-control"
              onChange={handleInputChange}
            >
              <option defaultValue>Select Designation</option>
              {jobData &&
                jobData.length > 0 &&
                jobData.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6">
            <label htmlFor="Role" className="form-label">
              Role
            </label>
            <select
              name="Role"
              id="RoleId"
              className="form-select array-select form-control"
              aria-label="example"
              onChange={handleInputChange}
            >
              <option defaultValue>Select Role</option>
              {userRoleData &&
                userRoleData.length > 0 &&
                userRoleData.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-12 col-sm-6">
            <label htmlFor="Team" className="form-label">
              Team
            </label>
            <select
              name="Team"
              className="form-select array-select form-control"
              id="TeamId"
              onChange={handleInputChange}
            >
              <option defaultValue>Select Team</option>
              {teamData &&
                teamData.length > 0 &&
                teamData.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-12 ">
            <label htmlFor="JoiningDate" className="form-label">
              Joining Date
            </label>
            <div className="md-form md-outline input-with-post-icon datepicker">
              <input
                name="JoiningDate"
                placeholder="Select date"
                type="date"
                id="JoiningDate"
                className="form-control"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-6 col-12 ">
            <label htmlFor="LeavingDate" className="form-label">
              Leaving Date
            </label>
            <div className="md-form md-outline input-with-post-icon datepicker">
              <input
                name="LeavingDate"
                className="form-control"
                id="LeavingDate"
                placeholder="Select date"
                type="date"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="col-6 col-sm-6">
          <label htmlFor="IsApprover" className="form-label">
            Is Approver
          </label>
          <select
            name="IsApprover"
            id="IsApprover"
            className="form-select array-select form-control"
            onChange={handleInputChange}
          >
            <option value="1">No</option>
            <option value="2">Yes</option>
          </select>
        </div>
        <div className="step-footer d-flex mt-3 gap-2">
          <button className="nextbtn" onClick={showUserComponent}>
            Prev
          </button>

          <button className="nextbtn" value="Save" onClick={onSubmit}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default EmploymentDetails;
