import { authReducer } from '../features/auth/authSlice'
import {configureStore} from '@reduxjs/toolkit'
import { fteReducer } from '../features/auth/authSlice'
export const store=configureStore({

    reducer:{
    auth:authReducer,
    fte: fteReducer,


    }
})

export default store