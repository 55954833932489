import React, { useState } from "react";
import Stickyheader from "../.././upperheader/Stickyheader";
import EmploymentDetails from "./EmploymentDetails";
import "../UserManage.css";
import UserDetails from "./UserDetails";
import { useDispatch } from "react-redux";
import { addEditUserManagement } from "../../../redux/features/auth/authSlice";

function AddUser({ toggleSidebarClass }) {
  const [isActive, setIsActive] = useState("first");
  const [userDetails, setUserDetails] = useState({});
  const [employmentDetails, setEmploymentDetails] = useState({});
  const dispatch = useDispatch();

  const showFirstComponent = () => {
    setIsActive("first");
  };

  const showSecondComponent = () => {
    setIsActive("second");
  };

  const handleUserDetailsChange = (newUserDetails) => {
    setUserDetails((prevDetails) => ({ ...prevDetails, ...newUserDetails }));
  };

  const handleEmploymentDetailsChange = (newEmploymentDetails) => {
    setEmploymentDetails((prevDetails) => ({
      ...prevDetails,
      ...newEmploymentDetails,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const combinedData = {
      ...userDetails,
      ...employmentDetails,
    };

    await dispatch(addEditUserManagement(combinedData));
  };

  return (
    <div className="ms-2">
     
      <main style={{ backgroundColor: "rgb(248, 246, 242)", height: "100vh" }}>
        <div className="page-toolbar px-xl-4 px-sm-2 px-0 py-3">
          <div className="container-fluid">
            <div className="row g-3 mb-3 align-items-center">
              <div className="col">
                <ol className="breadcrumb bg-transparent mb-0">
                  <li className="breadcrumb-item">
                    <a className="text-secondary" href="#">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a className="text-secondary" href="#">
                      Users
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Add
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="card step-app h-wizard-demo3 m-4">
          <ul className="step-steps col-12">
            <li data-step-target="step1" onClick={showFirstComponent}>
              <span
                className={`fa fa-user ${isActive === "first" ? "active" : ""}`}
              ></span>{" "}
              User Detail
            </li>
            <li data-step-target="step2" onClick={showSecondComponent}>
              <span
                className={`fa fa-credit-card col-6 ms-10 ${
                  isActive === "second" ? "active" : ""
                }`}
              ></span>
              Employment Detail
            </li>
          </ul>

          {isActive === "first" && (
            <UserDetails
              onChange={handleUserDetailsChange}
              showEmployeeComponent={showSecondComponent}
            />
          )}
          {isActive === "second" && (
            <EmploymentDetails
              onChange={handleEmploymentDetailsChange}
              showUserComponent={showFirstComponent}
              onSubmit={handleSubmit}
            />
          )}
        </div>
      </main>
    </div>
  );
}

export default AddUser;
