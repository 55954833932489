import  { lazy } from 'react';
import PropTypes from 'prop-types';
import PublicLayout from '../component/plugins/PublicLayout';
import LoginLayout from '../component/plugins/LoginLayout';
import '../App.css';
import Dashboardlayout from '../component/plugins/Dashboardlayout';
import AddUser from '../component/UserManagement/AddUser/AddUser';

const LandingPage =lazy(()=>import("../component/pages/landingScreens/LandingPage"))
const LoginScreen =lazy(()=>import(".././app/signin/page"))
// const SignupScreen =lazy(()=>import("../pages/signup/Signup"))
const Dashboard =lazy(()=>import("../app/(sharedLayout)/dashboard/Dashboard"))
const About = lazy(()=>import("../component/pages/aboutpage/About"))
// const ForgotPassword =lazy(()=>import("../pages/ForgotPassword/ForgotPassword.jsx"))
const CheckOut =lazy(()=>import("../component/pages/checkout/CheckOut"))
// const ResetPassword =lazy(()=>import("../pages/ResetPassword/ResetPassword.jsx"))
const ContactUs =lazy(()=>import("../component/pages/contact/Contact"))
const ServicesDetail =lazy(()=>import("../component/pages/services/servicesdetail/ServicesDetail"))
const ServiecesList =lazy(()=>import("../component/pages/services/ServiecesList"))
const Pricing = lazy(()=>import("../component/pages/pricing/Pricing"))
const Addjobtitle= lazy(()=>import("../component/adminside/Jobtitle/JobtitleAPI"))
const Jobtitlelist= lazy(()=>import("../component/adminside/Jobtitle/JobtitleList"))
const  Addemployeetype = lazy(()=>import("../component/adminside/employeetype/Employtype"))
const Employeetypoelist =lazy(()=>import("../component/adminside/employeetype/Employeelist"))
const Adddepartment =lazy(()=>import("../component/adminside/department/DepartmentApi"))
const Departmentlist =lazy(()=>import("../component/adminside/department/Departmentlist"))
const Addsubdepartment =lazy(()=>import("../component/adminside/sub-department/Subdepartmentapi"))
const Subdepartmentlist =lazy(()=>import("../component/adminside/sub-department/Subdepartmentlist"))
const Addproject =lazy(()=>import("../component/adminside/project/ProjectAPI"))
const Projectlist =lazy(()=>import("../component/adminside/project/projectlist"))
const Addteam =lazy(()=>import("../component/adminside/team/TeamAPI"))
const Teamlist =lazy(()=>import("../component/adminside/team/Teamlist"))
const Estimation =lazy(()=>import("../component/estimationdesign/estimationwithapi/Estimation"))
const EstimationReport =lazy(()=>import("../component/estimationdesign/estimationreport/EstimationReport"))
const Costing =lazy(()=>import("../component/dashbored/costing/CostingReport"))
const AllUsers=lazy(()=>import("../component/UserManagement/AllUsers"))
const AddUsers=lazy(()=>import("../component/UserManagement/AddUser/AddUser"))
const RoleList=lazy(()=>import("../component/UserManagement/RoleList"))
const AddRole=lazy(()=>import("../component/UserManagement/AddRole"))









const config = [ 
  {
  path: '/',
  loginRequired: false,
  layout: PublicLayout,
  component: LandingPage
},
{
  path: '/LoginScreen',
  loginRequired: false,
  layout: LoginLayout,
  component: LoginScreen
},
// {
//   path: '/signup',
//   loginRequired: false,
//   layout: LoginLayout,
//   component: SignupScreen
// },

// {
//   path: '/forgotpassword',
//   loginRequired: false,
//   layout: LoginLayout,
//   component:ForgotPassword
// },
// {
//   path: '/resetpassword/:id*',
//   loginRequired: false,
//   layout: LoginLayout,
//   component:ResetPassword
// }
,
{
  path: '/ContactUs',
  loginRequired: false,
  layout: PublicLayout,
  component:ContactUs
}
,
{
  path: '/About',
  loginRequired: false,
  layout: PublicLayout,
  component: About,
},
{
  path: '/CheckOut',
  loginRequired: false,
  layout: PublicLayout,
  component: CheckOut,
},
{
  path: '/ServicesDetail',
  loginRequired: false,
  layout: PublicLayout,
  component:ServicesDetail
}
,
{
  path: '/ServiecesList',
  loginRequired: false,
  layout: PublicLayout,
  component:ServiecesList
}
,
{
  path: '/Pricing',
  loginRequired: false,
  layout: PublicLayout,
  component:Pricing
},
{
  path: '/dashboard',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Dashboard
},
{
  path: '/addjobtitle',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Addjobtitle,
}
,
{
  path: '/jobtitlelist',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Jobtitlelist,
}
,
{
  path: '/addemployeetype',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Addemployeetype,
}
,
{
  path: '/employeetypelist',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Employeetypoelist,
}
,
{
  path: '/adddepartment',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Adddepartment,
}
,
{
  path: '/departmentlist',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Departmentlist,
}
,
{
  path: '/addsubdepartment',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Addsubdepartment,
}
,
{
  path: '/subdepartmentlist',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Subdepartmentlist,
}
,
{
  path: '/addproject',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Addproject,
}
,
{
  path: '/projectlist',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Projectlist,
}
,
{
  path: '/addteam',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Addteam,
}
,
{
  path: '/teamlist',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Teamlist,
}
,
{
  path: '/allusers',
  loginRequired: true,
  layout: Dashboardlayout,
  component:AllUsers,
}
,
{
  path: '/addusers',
  loginRequired: true,
  layout: Dashboardlayout,
  component:AddUsers,
}
,
{
  path: '/rolelist',
  loginRequired: true,
  layout: Dashboardlayout,
  component:RoleList,
}
,
{
  path: '/addrole',
  loginRequired: true,
  layout: Dashboardlayout,
  component:AddRole,
}
,
{
  path: '/estimation',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Estimation,
}
,
{
  path: '/estimationreport',
  loginRequired: true,
  layout: Dashboardlayout,
  component:EstimationReport,
}
,
{
  path: '/costing',
  loginRequired: true,
  layout: Dashboardlayout,
  component:Costing,
}
];

config.propTypes = {
  path: PropTypes.string.isRequired,
  loginRequired: PropTypes.bool,
  layout: PropTypes.elementType.isRequired,
  component: PropTypes.elementType.isRequired
};

export default config;
