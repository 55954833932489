
import {  Suspense } from "react";
import {ErrorBoundary} from "react-error-boundary"
import { AuthProvider } from './context/AuthProvider'
import Router from './router';
import Loader from "./component/plugins/Loader";
import { Provider } from 'react-redux';
import store from '../src/redux/store/store';
import { EstimationProvider } from './context/EstimationContext'; // Import the EstimationProvider
import { Toaster } from "react-hot-toast";
const App = () => {
  return (
    <ErrorBoundary fallback={<><p>error</p></>}>
 <Suspense fallback={
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
     <Loader/>
          </div>}>
          <Provider store={store}>
          <AuthProvider>
          <EstimationProvider> {/* Wrap with EstimationProvider */}
              <Router />
            </EstimationProvider>
          </AuthProvider>
          </Provider>
          <Toaster/>
          </Suspense>    
          </ErrorBoundary>
  )
}

export default App