import { useState, useEffect } from 'react';

const useAuth = () => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem('accessToken');
    return token ? { accessToken: token } : null;
  });

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setAuth({ accessToken: token });
    }
  }, []);

  return { auth, setAuth };
};

export default useAuth;
