import { createContext,useEffect,useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({children})=>{
    const [auth, setAuth] = useState({})

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
          setAuth({ accessToken: token });
        }
      }, []);

    return(
        <AuthContext.Provider value={{auth, setAuth}}>
{children}
        </AuthContext.Provider>
    )
}

export default AuthContext;