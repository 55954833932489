import React from "react";

function UserDetails({ onChange, showEmployeeComponent }) {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    onChange({ [name]: value });
  };
  return (
    <>
      <div className="card step-app h-wizard-demo3 ">
        <div className="step-content">
          <form className="step-tab-panel" role="tabpanel" data-step="step1">
            <div className="row">
              <div className="col-12 mb-2">
                <span className="control-fileupload">
                  <input
                    type="file"
                    name="ProfilePicture"
                    onChange={handleInputChange}
                  />
                </span>
              </div>
              <div className="col-md-4 col-12">
                <label htmlFor="FirstName" className="form-label">
                  First Name
                </label>
                <input
                  name="FirstName"
                  id="FirstName"
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-md-4 col-12">
                <label htmlFor="LastName" className="form-label">
                  Last Name
                </label>
                <input
                  name="LastName"
                  id="LastName"
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-12">
                <label htmlFor="Email" className="form-label">
                  Email Adress
                </label>
                <input
                  name="Email"
                  type="email"
                  id="Email"
                  required
                  className="form-control"
                  placeholder="Email address"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4 col-12">
                <label htmlFor="PhoneNumber" className="form-label">
                  Moblie Number
                </label>
                <input
                  name="PhoneNumber"
                  type="text"
                  required
                  id="PhoneNumber"
                  className="form-control"
                  placeholder="Enter Mobile number"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4 col-12 ">
                <label htmlFor="DateOfBirth" className="form-label">
                  Date OF Birth
                </label>
                <div className="md-form md-outline input-with-post-icon datepicker">
                  <input
                    name="DateOfBirth"
                    placeholder="Select date"
                    type="date"
                    id="example"
                    required
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-6">
                <label htmlFor="PasswordHash" className="form-label">
                  Password
                </label>
                <input
                  name="PasswordHash"
                  id="PasswordHash"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-4 col-6">
                <label htmlFor="ConfirmPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  name="ConfirmPassword"
                  id="ConfirmPassword"
                  type="password"
                  required
                  className="form-control"
                  placeholder="Confirm Password"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-6">
                <label htmlFor="Country" className="form-label">
                  Country
                </label>
                <input
                  name="Country"
                  id="Country"
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Country"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-8">
                <label htmlFor="Address" className="form-label">
                  Address
                </label>
                <input
                  name="Address"
                  id="Address"
                  type="text"
                  className="form-control"
                  placeholder="Enter Address"
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button className="nextbtn mt-3" onClick={showEmployeeComponent}>
              Next
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default UserDetails;
